.social-profile
  border: 1px solid rgba(218, 220, 224, 0.5)
  border-radius: 8px
  margin: 12px
  .profile-info
    display: grid
    grid-template-columns: 100px 1fr
    grid-gap: 10px
    gap: 10px
    font-size: 12px
    border-top: 1px solid rgba(218, 220, 224, 0.5)
    padding: 10px
    overflow: hidden
  .profile-title
    display: flex
    align-items: center
    padding: 5px 10px
    .profile-name
      margin-left: 10px
      font-size: 13px
      &__title
        font-weight: 600
      &__sub
        color: #8d8d8f
    .profile
      width: 40px
      height: 40px
      margin: 0
      position: relative
      overflow: visible
      .picture
        border-radius: 50%
      .social-icon
        position: absolute
        z-index: 1
        bottom: -2px
        right: -3px
        background: #747474
        color: #fff
        padding: 2px
        border-radius: 50%
