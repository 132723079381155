.filter-wrap
  position: absolute
  top: -90px
  right: -365px
  width: fit-content
  border-radius: 5px
  z-index: 999
  .ant-card
    box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.15)
  .ant-card-body
    padding: 10px
  .ant-collapse
    border: none
    max-height: 80vh
    overflow: auto
    .ant-collapse-item
      border-bottom: none
      .ant-collapse-header
        background: #fff
        text-align: left
        padding: 0 16px
        .ant-collapse-header-text
          font-weight: 600
      .ant-collapse-content
        border-top: none
        .ant-collapse-content-box
          text-align: left
  .ant-checkbox-wrapper
    margin-bottom: 10px
  .ant-checkbox-group
    display: flex
    flex-direction: column
  .wrapper
    max-height: 500px
    overflow: auto
    .item
      display: flex
      justify-content: flex-start
      align-items: center
      margin-bottom: 12px
      cursor: pointer
      .color
        margin-right: 10px
        width: 15px !important
        height: 15px !important
      .tag-name
        font-size: 14px
        margin-right: 10px
      .icon-check
        color: #0067ac
        font-size: 17px