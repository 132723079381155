.popup-confirm-content__preview
  margin-left: 35px
  // border-left: 3px solid #cacaca
  padding-left: 35px

.preview-container
  background-color: #f6f7f9
  border: 9px solid #fff
  border-radius: 48px
  box-shadow: 0 0 10px #2121211a
  height: 615px
  padding: 9px
  position: -webkit-sticky
  position: sticky
  top: 0
  width: 313px
  .preview-status-bar
    align-items: center
    display: flex
    height: 28px
    justify-content: space-between
    width: 277px
    &__time
      font-size: 12px
      font-weight: 590
      line-height: 14px
      margin-left: 18px
    &__energy
      align-items: center
      display: flex
      height: 10px
      justify-content: center
      margin-right: 18px
      width: 54px
    &__white-block
      background-color: #f6f7f9
      border-radius: 0 0 14px 14px
      border-top: #fff
      height: 22px
      left: 50%
      position: absolute
      top: 9px
      transform: translateX(-50%)
      width: 110px
  .preview-store-info
    align-items: center
    display: flex
    height: 35px
    width: 277px
    &__arrow-image
      align-items: center
      display: flex
      height: 20px
      justify-content: center
      margin-left: 6px
      width: 20px
    &__avatar
      margin-left: 6px
    &__name
      font-size: 14px
      font-weight: 500
      line-height: 20px
      margin-left: 8px
      max-width: 185px
      display: -webkit-box
      -webkit-line-clamp: 1
      -webkit-box-orient: vertical
      overflow: hidden
      text-overflow: ellipsis
  &__body
    height: calc(100% - 120px)
  &__screen
    background-color: #fff
    border-radius: 32px
    height: 100%
    width: 277px
  .row-wrapper
    align-items: flex-end
    display: flex
    font-size: 12px
    line-height: 20px
    margin: 4px 0
    &__children
      flex: 1 1
      margin-left: 8px
      min-width: 0
    &__reply
      margin: 8px 0
      text-align: end
      > div
        background-color: #0084ff
        border-radius: 12px
        color: #fff
        display: inline-block
        font-size: 12px
        line-height: 20px
        padding: 8px 12px

.card-notify
  background-color: #f1f1f4
  border-radius: 12px
  position: relative
  width: 195px
  &__image
    border-radius: 12px 12px 0 0
    height: 100px
    object-fit: cover
    width: 100%
    &.square
      height: 195px
  &__info
    border-radius: 0 0 12px 12px
    padding: 8px
    width: 100%
  &__title
    color: #000
    font-weight: 700
    white-space: pre-wrap
    margin: 0
  
.preview-messages
  height: 100%
  overflow-y: auto
  padding: 8px 8px 0
  position: relative
  scroll-behavior: smooth
  scrollbar-width: none
  width: 277px
  .text-content-empty-error
    background-color: #ffefef!important
    border: 1px solid #fec0be!important
    border-radius: 8px
    color: #ff542e!important
    font-weight: 400!important
    min-height: 27px
    padding: 5px
    width: 179px
    white-space: pre-wrap
    word-break: break-word
    margin: 0

.card__btn-container
  align-items: center
  background-color: #f1f1f4
  display: flex
  flex-direction: column
  > p
    align-items: center
    background-color: #e4e6eb
    border-radius: 7px
    display: flex
    font-weight: 500
    height: 36px
    justify-content: center
    margin: 8px 0 0
    overflow: hidden
    padding: 6px
    text-overflow: ellipsis
    white-space: nowrap
    width: 100%

.preview-footer
  height: 57px
  &__menu
    align-items: center
    display: flex
    height: 36px
    justify-content: space-between
    padding: 6px 8px
    width: 100%
    &-item-1
      align-items: center
      display: flex
      height: 18px
      justify-content: center
      width: 18px
    &-item-2
      align-items: center
      display: flex
      height: 25px
      justify-content: center
      width: 185px
    &-item-3
      align-items: center
      display: flex
      height: 12px
      justify-content: center
      width: 16px
  &__bottom-bar
    align-items: center
    display: flex
    height: 21px
    justify-content: center
    width: 100%
    > div
      background-color: #000
      border-radius: 100px
      height: 5px
      width: 90px
      
.card-text
  background-color: #f1f1f4
  border-radius: 12px
  display: inline-block
  max-width: 195px
  padding: 8px 12px
  &__content
    color: #000
    margin: 0
    word-break: break-word
    > span
        white-space: pre-wrap

.hrv-avatar
  align-items: center
  background: #f4f6f8
  border-radius: 50%
  box-sizing: border-box
  color: #212121
  color: #fff
  display: flex
  font-size: 14px
  font-weight: 700
  height: 32px
  justify-content: center
  line-height: 32px
  list-style: none
  margin: 0
  overflow: hidden
  padding: 0
  position: relative
  white-space: nowrap
  width: 32px
  > img
      display: block
      height: 100%
      object-fit: cover
      width: 100%

.config-send-template-review .popup-confirm-content__preview
  padding-left: 5px
  margin-left: 5px