.panel
  height: 100%
  flex-grow: 1
  border-right: 1px solid rgba(218, 220, 224, 0.5)
  .room-skeleton
    margin-top: 10px
    .ant-skeleton
      padding: 0 16px
      border-bottom: 1px solid rgba(218,220,224,.3)
      margin-bottom: 14px
      .ant-skeleton-title
        margin-block-start: 0
        width: 30% !important
      .ant-skeleton-paragraph
        margin-block-start: 10px !important
        & >li + li
          margin-block-start: 10px
  .notice
    font-size: 12px
    color: #666
    text-align: center
    padding: 18px
  .rooms
    height: calc(100% - 170px)
    overflow-y: scroll
    border-top: 1px solid rgba(218, 220, 224, 0.5)
    -webkit-overflow-scrolling: touch
    .icon-user-room
      margin-right: 7px
      width: 16px
      min-width: 16px 
.loading-block
  margin-top: 100px

@media screen and (min-width: 1200px)
  .panel
    max-width: 25vw
    min-width: 25vw

@media screen and (min-width: 1920px)
  .panel
    max-width: 500px

@media screen and (min-width: 700px) and (max-width: 1199px)
  .panel
    max-width: 300px
    min-width: 300px

@media screen and (max-width: 699px)
  .panel
    width: 100%

.theme.light .panel
  background: #fff

.theme.dark .panel
  background: #303841

.active
  color: #da7d02 !important
