.report-header
  padding: 5px
  background: #0067AC
  .content-header
    display: flex
    justify-content: space-between
    max-width: 100%
    min-width: 1270px
    width: 100%
  .page-lists
    display: block
    position: relative
    padding: 5px 12px
    border-radius: 10px
    text-decoration: none
    &:hover
      background: rgba(29, 41, 57, 0.25)
      > .dropdown-content
        display: block
    .page-selected
      display: flex
      align-items: center
      cursor: pointer
      .page-icon
        width: 35px
        img
          border-radius: 50%
          border: 2px solid #fff
      .page-name
        color: #fff
        font-size: 14px
        text-decoration: none
        margin-left: 10px
        overflow: hidden
        vertical-align: middle
        text-overflow: ellipsis
        max-width: 200px
        font-weight: 400
        white-space: nowrap
        text-align: center
        display: inline-block
    .dropdown-content
      display: none
      flex-direction: column
      position: absolute
      background-color: #fff
      top: 47px
      right: 0
      box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.15)
      width: 260px
      z-index: 9999
      border-radius: 6px
      padding: 5px
      max-height: 400px
      overflow-y: auto
      .page-item
        display: flex
        align-items: center
        padding: 5px
        border-radius: 5px
        cursor: pointer
        &:hover
          background: #F2F4F7
        .page-item-icon
          position: relative
          width: 40px
          min-width: 40px
          img
            border-radius: 50%
            border: 2px solid #fff
          .social-icon
            position: absolute
            width: 22px
            height: 22px
            bottom: 0px
            right: -3px
        .page-item-name
          color: #333
          font-size: 14px
          margin-left: 10px
          overflow: hidden
          vertical-align: middle
          text-overflow: ellipsis
          max-width: 200px
          font-weight: 400
          white-space: nowrap
          text-align: center
          display: inline-block
.page-statistic
  height: calc(100% - 50px)
  width: 100%
  margin: 0px auto
  max-width: 100%
  min-width: 1270px
  display: -webkit-box
  padding: 0px
  .page-statistic-menu
    width: 280px
    position: sticky
    top: 0px
    height: calc(100vh - 55px)
    .title-statistic
      margin: 16px 24px
      color: #1D2939
      font-size: 24px
      font-weight: 500
      line-height: 32px
      display: block
    .item-statistic
      height: 44px
      display: flex
      margin: 4px 16px
      margin-top: 0px
      border-radius: 8px
      align-items: center
      padding: 0px 16px
      color: var(--Gray-800, #1D2939)
      cursor: pointer
      text-decoration: none
      .setting-title
        margin-left: 12px
        line-height: 22px
        user-select: none
        text-decoration: none
        color: #1d2939
    .item-statistic-selected
      background: var(--blue-hover, #E3F2FD)
      color: var(--primary-button-defaul, #2D65C3)
      font-weight: 500
  .page-statistic-content
    width: calc(100% - 280px)
    .page-statistic-content-body
      padding: 32px
      background: var(--Gray, #ECEDF4)
.activity-overview
  height: 415px
  .content
    height: 299px
.overview-statistic
  color: #1D2939
  display: flex
  -webkit-box-orient: vertical
  -webkit-box-direction: normal
  -ms-flex-direction: column
  flex-direction: column
  gap: 24px
  .overview-statistic-header
    display: flex
    align-items: center
    justify-content: space-between
    .title
      font-weight: 500
      font-size: 20px
      line-height: 28px
  .main-content
    gap: 24px
    grid-gap: 24px
    display: grid

.card
  border-radius: 8px
  padding: 24px
  display: flex
  flex-direction: column
  gap: 24px
  background-color: #ffffff
  border: 0
  .header
    display: flex
    gap: 16px
    justify-content: space-between
    align-items: start
    .title-desc
      display: flex
      flex-direction: column
      gap: 4px
      .title
        color: #101828
        font-weight: 500
        font-size: 16px
      .desc
        color: #667085
        font-size: 12px
        line-height: 16px

.page-overview-content
  display: flex
  flex-direction: column
  gap: 16px
  .page-inbox
    display: flex
    gap: 16px
    padding: 6px 8px
    border-radius: 8px
    align-items: center
    .icon
      position: relative
      border-radius: 8px
      overflow: hidden
      .iframe
        border-radius: inherit
        border: 1.5px solid #ffffff
        position: absolute
        width: 100%
        height: 100%
    .content
      display: flex
      flex-direction: column
      gap: 4px
      .desc
        font-size: 14px
        line-height: 22px
        color: #475467
      .count
        font-size: 20px
        line-height: 28px
  .page-comment
    display: flex
    gap: 16px
    padding: 6px 8px
    border-radius: 8px
    align-items: center

.chart-container canvas
  height: 299px

.engagement-overview-content
  display: flex
  gap: 16px

.total-and-inbox-comment
  border-radius: 12px
  outline: #dde1e7 solid 1px
  padding: 16px
  display: flex
  gap: 16px
  margin: 1px

.chart-box
  display: flex
  flex-direction: column
  gap: 16px
  .info
    display: flex
    flex-direction: column
    .count
      font-size: 24px
      line-height: 32px
    .desc
      font-size: 14px
      line-height: 22px
      color: #667085

.separator
  width: 1px
  background-color: #eaecf0

.other-overview
    display: flex
    flex-direction: row
    gap: 24px
    > div:nth-child(1)
        max-width: 350px
    .flex-c
      display: flex
      flex-direction: column
      gap: 24px
      width: 100%

.top-tags-content
  display: flex
  flex-direction: column
  gap: 8px
  .tag-item
    display: flex
    gap: 8px
    justify-content: space-between
    padding: 4px 6px
    border-radius: 6px
    .icon-and-name
      display: flex
      gap: 8px
      align-items: center
      .icon
        width: 20px
        height: 20px
      .name
        line-height: 22px

.staff-overview-content
  display: grid
  grid-auto-columns: 1fr
  grid-template-rows: repeat(5, minmax(0, 1fr))
  grid-auto-flow: column
  gap: 8px
  .staff-item
    border-radius: 8px
    padding: 8px 12px
    display: flex
    gap: 16px
    align-items: center
    .avatar-and-rank
      position: relative
      width: 60px
      min-width: 60px
      height: 60px
      margin: 7px 12px
      border-radius: 0
      .img
        display: flex
        justify-content: center
        width: 50px
        height: 50px
        border-radius: 50%
        align-items: center
        background: #0067AC
        color: #f7f7f8
        font-size: 18px
      img
        box-sizing: content-box
        border-radius: 9999px
        width: 50px
        height: 50px
        border: 2px solid #ffffff
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)
      .rank
        box-sizing: content-box
        position: absolute
        top: 33px
        left: 36px
        width: 16px
        height: 16px
        border-radius: 9999px
        border: 1px solid #ffffff
        background-color: #eb3535
        color: #fff
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)
        display: flex
        justify-content: center
        align-items: center
        font-weight: 700
        line-height: 22px
        font-size: 11px
    .detail
      display: flex
      flex-direction: column
      gap: 4px
      .name
        line-height: 22px
        color: #101828
      .engagement
        display: flex
        gap: 6px
        align-items: center
        color: #667085
        .count
          line-height: 22px

.view-ratio-statistic
  display: flex
  .view-ratio-item
    max-width: 256px
    width: 100%
    height: 86px
    border-radius: 8px
    background: #FFF
    padding: 16px
    margin-right: 24px
    display: flex
    align-items: center
    .img-item
      width: 52px
      height: 52px
    .data-ratio
      flex: auto
      margin-left: 16px
      display: flex
      flex-direction: column
      .ratio-count
        font-weight: 600
      .number
        margin-right: 8px
        font-size: 20px
        line-height: 28px
        color: #1D2939
      .title-ratio
        line-height: 22px
        color: #344054
        margin-top: 4px

.image-box
  position: relative
  width: 50px
  min-width: 50px
  height: 50px
  margin: 7px 12px
  border-radius: 0
  img
    border-radius: 50%
  .img
    display: flex
    justify-content: center
    width: 50px
    height: 50px
    border-radius: 50%
    align-items: center
    background: #0067AC
    color: #f7f7f8
    font-size: 18px

.avatar-name
  position: relative
  width: 60px
  min-width: 60px
  height: 60px
  margin: 7px 12px
  border-radius: 0
  .img
    display: flex
    justify-content: center
    width: 60px
    height: 60px
    border-radius: 50%
    align-items: center
    background: #666
    color: #f7f7f8
    font-size: 18px

.un-link
  text-decoration: none !important
  color: #1677ff

.loading-content
  padding: 50px
  border-radius: 4px
  width: 100%
  height: 100%

.cursor-pointer
  cursor: pointer

.session-summary
  &--content
      margin-top: 15px
      p
          margin-bottom: 7px
      li
          margin-bottom: 7px
      h1
          font-size: 17px
          color: #0067ac
          font-weight: 600
          text-align: center
      h2
          font-size: 15px
          color: #0067ac
          font-weight: 600
      h3
          font-size: 14px
          color: #0067ac
          font-weight: 600
      .ant-card
          margin-bottom: 15px
      .ant-tag
          max-height: 60vh
          overflow-y: auto
          width: 100%
          white-space: normal
          font-size: 14px
          padding: 10px

.page-statistic-content-body
  height: unset !important